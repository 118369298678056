import "./_tjanster.css";
import React from "react";

export const Tjänster = () => {
  return (
    <div className="tjanster">
      <div className="tjanster-section">
        <h1>
          <b>Våra Tjänster</b>
        </h1>

        <div className="tjanst">
          <br />

          <h4>
            <b>TVÄTT:</b>
          </h4>

          <p>Alingsås Tvättcenter AB erbjuder det mesta inom tvätt.</p>

          <p>
            • Vattentvätt
            <br />
            • Kemtvätt
            <br />
            • Vittvätt
            <br />
            • Skinn- & mockatvätt
            <br />
            • Hushållstvätt
            <br />
            • Press & mangling
            <br />
            • Mattvätt
            <br />
            • Tvätt av gardiner
            <br />
            • Tvätt av arbetskläder
            <br />• Tvätt av restauranggods
          </p>

          <p>
            Det mesta går att tvätta i vatten och det är det bästa för miljön. När det inte är
            möjligt med vattentvätt, tvättar vi kläderna med en kemisk tvättmetod där perkloretylen
            används, det är den metod som oftast förknippas med kemtvätt. Oavsett om olyckan varit
            framme eller om du bara behöver fräscha upp ett favoritplagg kan vi hjälpa dig. Det
            finns många typer av material som kan vara svåra att tvätta själv. Skinn, mocka, ull och
            siden hör till de typer av material som ofta kräver kemtvätt eller mycket skonsam
            vattentvätt. Om du vill att dina plagg ska hålla i många år framöver är det bästa att
            lämna in dina plagg till oss så att de tas om hand på rätt sätt.
          </p>

          <p>Vi erbjuder även tvätt och uthyrning av moppar för företag och städfirmor.</p>
          <br />
        </div>

        <div className="tjanst">
          <br />
          <br />
          <h4>
            <b>VITTVÄTT:</b>
          </h4>

          <p>
            Alingsås Tvättcenter tvättar och manglar dina lakan, dukar och servetter. Lämna in din
            vittvätt till oss och upplev hur skönt det är med välmanglat linne.
          </p>

          <p>
            All vittvätt som lämnas in hos oss fläckas, tvättas och manglas innan paketering. Om ni
            endast önskar tvätt av era plagg går det bra att lämna in det som hushållstvätt, då
            tvättas, torkas och viks tvätten innan den paketeras.
          </p>
          <br />
        </div>

        <div className="tjanst">
          <br />
          <br />
          <h4>
            <b>MATTOR:</b>
          </h4>

          <p>
            En ren och snygg matta på golvet kan göra underverk för ett rum, samtidigt som det ger
            ett trevligt intryck för gäster, personal och kunder. Låt oss på Tvättcenter ta hand om
            din mattvätt, vi tvättar alla typer av mattor. En matta behöver vård och underhåll för
            att inte slitas ut och förstöras. Mattor som inte rengörs tappar sin lyster och blir med
            tiden även tillhåll för kvalster och damm. Med en tvätt förlänger du mattans livslängd
            och skyddar den.
          </p>

          <p>
            De mattor vi själva inte kan tvätta, tvättas på en modern tvättanläggning i Borås hos
            Lundaskogstvätten. När du lämnar in din matta till oss kan du vara säker på att den är i
            erfarna händer och tvättas med omsorg och noggrannhet. Vi använder inga kemikalier som
            är miljö- eller hälsofarliga.
          </p>

          <p>Välkommen att kontakta oss för tvätt av dina mattor!</p>
          <br />
          <h4>
            <b>ABONNEMANG ENTRÈMATTOR:</b>
          </h4>

          <p>
            Unna er ett abonnemang av entrémattor, smidigt och alltid fräscht! Hos Alingsås
            Tvättcenter kan ni hyra eller köpa era entrémattor, välj mellan en standardmatta eller
            en profilerad matta med er logotyp eller varför inte något roligt motiv. Våra
            entrémattor är professionellt tvättbara torkmattor för entréer med hög trafik och
            överlägsen färgbeständighet. Vi ser till att era entrémattor byts ut och rengörs
            regelbundet, så att era kunders första intryck alltid blir det bästa.
          </p>

          <p>Välkommen att kontakta oss för att hyra eller köpa dina mattor!</p>
          <br />
        </div>

        <div className="tjanst">
          <br />
          <br />
          <h4>
            <b>UTHYRNING:</b>
          </h4>

          <div className="uthyrning3">
            <div className="text">
              <b>Uthyrning av dukar och servetter</b>
              <br />
              <br />
              Alingsås Tvättcenter hyr ut linnedukar, linneservetter och frottéhanddukar till både
              privatpersoner och företag. En dukning är sällan komplett utan en linneduk och
              linneservett. Vi erbjuder självklart dukar i olika storlekar.
              <br />
              <br />
              Ni håller tillställning och har trevligt, vi tvättar!
            </div>
            <div className="produkter">
              <b>Dukar:</b>
              <br />
              • 130x180 cm - 8 st
              <br />
              • 130x230 cm - 17 st
              <br />
              <br />
              <b>Servetter:</b>
              <br />• 50x50 cm - 90 st
            </div>
          </div>

          <div>
            <p>
              <br />
              <b>Uthyrning av tvättmaskin</b>
              <br />
              <br />
              Alingsås Tvättcenter hyr ut mattvättmaskin för rengöring av mattor och möbler i
              hemmet. Mattvättmaskinen fungerar lika bra till heltäckningsmattor, vanliga
              golvmattor, möbler som bilklädsel då det finns flera munstycken för olika
              användningsområden. Maskinen är mycket enkel att använda och lätt att manövrera. En
              tydlig användarbeskrivning följer alltid med vid uthyrning.
              <br />
              <br />
              Välkommen att kontakta oss för att hyra vår mattvättmaskin!
            </p>
          </div>
          <br />
        </div>

        <div className="tjanst-last">
          <br />
          <br />
          <h4>
            <b>HÄMTNING & LEVERANS:</b>
          </h4>

          <p>
            Hinner du inte förbi med din tvätt? Vi ordnar det! Alingsås Tvättcenter hämtar och
            levererar tvätt till privata- och företagskunder i hela Alingsås kommun. Vi har även
            kunder och möjlighet att hämta och leverera tvätt i Vårgårda, Floda, Lerum, Partille och
            Göteborg.
          </p>

          <p>Välkommen att kontakta oss för hämtning av din tvätt!</p>
        </div>
      </div>
    </div>
  );
};
