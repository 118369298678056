import "./_footer.css";
import logo from "../../assets/logos/whiteLogo.png";
import phoneIcon from "../../assets/icons/phone.svg";
import mailIcon from "../../assets/icons/mail.svg";
import locationIcon from "../../assets/icons/location.svg";
import facebookIcon from "../../assets/icons/facebook.svg";
import linkedinIcon from "../../assets/icons/linkedin.svg";

export const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="columns">
            <div className="column">
              <a href="/">
                <img className="footerLogo" src={logo} height="150" />
              </a>
            </div>

            <div className="column">
              <h5>ALINGSÅS</h5>
              <p>
                <a href="/kontakt">
                  <img src={phoneIcon} width="18" /> 0322-108 34
                </a>
              </p>
              <p>
                <a href="/kontakt">
                  <img src={mailIcon} width="20" /> info@tvattcenter.nu
                </a>
              </p>
              <p>
                <a href="/kontakt">
                  <img src={locationIcon} width="22" /> Färgaregatan 14
                  <br />
                  441 30 Alingsås
                </a>
              </p>
            </div>
            <div className="column">
              <h5>LERUM</h5>
              <p>
                <a href="/kontakt">
                  <img src={phoneIcon} width="18" /> 079-336 05 15
                </a>
              </p>
              <p>
                <a href="/kontakt">
                  <img src={mailIcon} width="20" /> info@tvattcenter.nu
                </a>
              </p>
              <p>
                <a href="/kontakt">
                  <img src={locationIcon} width="22" /> Göteborgsvägen 2B
                  <br />
                  443 30 Lerum
                </a>
              </p>
              <div className="socials">
                <a href="https://www.facebook.com/AlingsasTvattcenter" target="_blank">
                  <img src={facebookIcon} width="48" />
                </a>{" "}
                <a href="https://www.linkedin.com/company/alingsås-tvättcenter-ab" target="_blank">
                  <img src={linkedinIcon} width="48" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Copyright © 2022 - {new Date().getFullYear()} Tvättcenter Alingsås AB</p>
        <p className="divider">•</p>
        <p>
          Utvecklad och drivs av{" "}
          <a href="https://erlandev.com" target="_blank">
            <b>ErlanDev AB</b>
          </a>
        </p>
      </div>
    </>
  );
};

export default Footer;
