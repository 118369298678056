import "./_hemtext.css";
import React from "react";
import { Link } from "react-router-dom";
import Tvatt from "../../assets/tvatt.jpg";
import Hallbarhetsredovisning from "../../assets/Hallbarhetsredovisning-2021.pdf";

export const HemText = () => {
  return (
    <div className="hemtext">
      <div className="hemtext-section">
        <br />
        <p>
          <b>Alingsås Tvättcenter AB</b> finner du i centrum av <b>Alingsås</b> och i utkanten av{" "}
          <b>Lerum</b>. Vi erbjuder tvätt av kläder och andra textiler
          <br />
          <br />
          <b>Alingsås Tvättcenter</b> är marknadsledande inom vår bransch. Vi har personal som
          värdesätter den hantverksmässiga noggrannheten som krävs.
          <br />
          <br />
          Vi arbetar efter ett enhetligt ledningssystem som följer kraven enligt <b>
            ISO 9001
          </b>, <b>ISO 14001</b>, <b>ISO 45001</b> samt <b>svensk miljödiplomering</b>.
          <br />
          <br />
          Läs vår{" "}
          <a className="link" href={Hallbarhetsredovisning} target="_blank">
            hållbarhetsredovisning för 2021
          </a>
          .
          <br />
          <br />
          Från och med <b>1 januari 2021</b> kan du använda <b>RUT-avdrag</b> för inlämning av tvätt
          vid tvättinrättning. Läs mer under{" "}
          <Link className="link" to="/rutavdrag">
            Rutavdrag
          </Link>
          .
        </p>
        <p className="bild">
          <b>
            Tänk dig en egen kemtvätt på jobbet!
            <br />
            Din tid är värdefull, låt oss göra jobbet åt dig.
            <br />
            <img src={Tvatt} alt="En bild på en hög med tvätt" />
          </b>
        </p>
      </div>
    </div>
  );
};
