import "./_foretag.css";
import React from "react";
import ForetagBild from "../../assets/foretag.png";

export const Företag = () => {
  return (
    <div className="foretag">
      <div className="foretag-section">
        <br />
        <div className="middle-text">
          <p>
            <h4>Tänk dig en egen kemtvätt på jobbet!</h4>
            Din tid är värdefull, låt oss göra jobbet åt dig
          </p>
        </div>
        <div className="flex-section">
          <p className="flex-text">
            <h4>Leverans</h4>
            Vi hämtar och levererar er tvätt på arbetsplatsen.
          </p>
          <p className="flex-text">
            <h4>Faktura</h4>
            Många företag väljer att erbjuda våra tjänster till sina anställda. Vi har
            faktureringssystem där kunderna kan faktureras antingen som enskilda personer eller
            genom det beställande företaget.
          </p>
          <p className="flex-text">
            <h4>Ingen bindningstid</h4>
            Våra kunder behöver inte binda sig utan betalar endast för de tjänster som utförts.
          </p>
        </div>
        <div className="middle-text">
          <img src={ForetagBild} alt="Tvättar för företag" />
        </div>
        <div className="left-text">
          <p>
            Alingsås Tvättcenter tvättar åt företag inom en rad olika branscher, vi har kunder inom
            bland annat livsmedels- & verkstadsindustrin, vård- & serviceverksamhet, hotell och
            restaurang.
            <br />
            <br />
            Vi ser till att kockkläder, kökstvätt samt restauranglinne såsom dukar och servetter
            blir professionellt omhändertagna vilket ger ett fräscht och välkomnande intryck för
            kunderna.
            <br />
            <br />
            <h4>Lagning av arbetskläder</h4>
            Vi erbjuder även lagning av arbetskläder, från små hål till byte av dragkedja.
          </p>
        </div>
      </div>
    </div>
  );
};
