import "./_ombud.css";
import React from "react";
import phoneIcon from "../../assets/icons/phone.svg";
import locationIcon from "../../assets/icons/location.svg";

export const Ombud = () => {
  return (
    <div className="ombud">
      <div className="ombud-section">
        <div className="bild-text">
          <div className="text">
            <h3>
              <b>OMBUD:</b>
            </h3>
            <br />
            <b>Järnia Sollebrunn</b>
            <br />
            <a href="https://goo.gl/maps/5vp9tUyeu1AcJY8f6?coh=178573&entry=tt" target="_blank">
              <img src={locationIcon} width="18" /> Gräfsnäsvägen 2,
              <br />
              441 70 Sollebrunn
            </a>
            <br />
            <a href="tel:032240330">
              <img src={phoneIcon} width="16" /> 0322-403 30
            </a>
            <br />
            <br />
            Hämtning/leverans: Måndag
            <br />
            <br />
            <br />
            <br />
            <b>Ahlqvist Papper & Lek</b>
            <br />
            <a href="https://goo.gl/maps/wZWd25GWFxM8Sw9b6?coh=178573&entry=tt" target="_blank">
              <img src={locationIcon} width="18" /> Kungsgatan 48,
              <br />
              447 30 Vårgårda
            </a>
            <br />
            <a href="tel:0322623516">
              <img src={phoneIcon} width="16" /> 0322-62 35 16
            </a>
            <br />
            <br />
            Hämtning/leverans: Måndag
            <br />
            <br />
            <br />
            <br />
            <b>ELON Ljungs i Lerum</b>
            <br />
            <a href="https://goo.gl/maps/GgSxPfuESadQdnYD9?coh=178573&entry=tt" target="_blank">
              <img src={locationIcon} width="18" /> Göteborgsvägen 2B,
              <br />
              443 30 Lerum
            </a>
            <br />
            <a href="tel:0320213230">
              <img src={phoneIcon} width="16" /> 03202-132 30
            </a>
            <br />
            <br />
            Hämtning/leverans: Måndag, Onsdag & Fredag
            <br />
            <br />
          </div>
          <div className="bild">
            <iframe
              className="karta"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5960.193676652203!2d12.529167831149683!3d58.119049008713176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464549e2b4f8db57%3A0x1fd1968ee80e9014!2sJ%C3%A4rnia%20Sollebrunn!5e0!3m2!1ssv!2sse!4v1684164842366!5m2!1ssv!2sse"
              width="600"
              height="250"
              loading="lazy"
            />
            <iframe
              className="karta"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56866.120298314985!2d12.716241177441635!3d58.01820245559331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465ac78f20e1238f%3A0x616cc4e63402b8ff!2sAhlqvist!5e0!3m2!1ssv!2sse!4v1684165913736!5m2!1ssv!2sse"
              width="600"
              height="250"
              loading="lazy"
            />
            <iframe
              className="karta"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5061.534758765357!2d12.255518656863789!3d57.765480314133164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff81206d6ebf5%3A0x88b2811cae46644f!2sElon%20Ljungs!5e0!3m2!1ssv!2sse!4v1684165966049!5m2!1ssv!2sse"
              width="600"
              height="250"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
