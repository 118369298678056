import "./_rutavdrag.css";
import React from "react";
import RutavdragBild from "../../assets/rutavdrag.jpg";

export const Rutavdrag = () => {
  return (
    <div className="rutavdrag">
      <div className="rutavdrag-section">
        <p>
          <div className="bild-text">
            <div className="text">
              <h3>
                <b>RUTAVDRAG:</b>
              </h3>
              <br />
              Från och med 1 januari 2021 kan du som privatperson använda RUT-avdraget för inlämning
              av tvätt vid tvättinrättning.
              <br />
              <br />
              Arbetskostnaden ska beräknas enligt schablon till 50 procent av den totala kostnaden
              för tjänsten, inklusive transport av tvättgods. Rutavdraget blir sedan 50 procent av
              den beräknade arbetskostnaden, det vill säga 25 procent av totalkostnaden.
            </div>
            <div className="bild">
              <img src={RutavdragBild} alt="Rutavdrag" />
            </div>
          </div>
          <br />
          <b>Krav för rutavdrag:</b>
          <br />
          • Tvätten ska ingå i kundens hushåll.
          <br />
          • Företaget som utför tvätten och som begär utbetalning för tjänsten från Skatteverket ska
          vara en tvättinrättning,
          <br />
          det vill säga ett tvätteri som har sin verksamhet i lokaler inom EU/EES och Schweiz i
          enlighet med 39 kap. 2 § SFL.
          <br />
          • Det ska finnas underlag som visar att kunden och företaget har avtalat om att använda
          rutavdrag.
          <br />
          <br />
          <b>Rutavdrag ges för att:</b>
          <br />
          • Utföra vattentvätt av kläder och hemtextilier, till exempel sängkläder, handdukar och
          gardiner.
          <br />
          • Torka, stryka, mangla och vika tvättgods.
          <br />
          • Hämta och lämna tvätt, även vid depåservice.
          <br />
          • Laga kläder och hemtextilier i samband med tvätt.
          <br />
          • Lägga upp kläder och gardiner.
          <br />
          <br />
          <b>Inget avdrag ges för att:</b>
          <br />
          • Utföra kemtvätt.
          <br />
          • Tvätta stora mattor oavsett om det sker i specialmaskin eller manuellt.
          <br />
          • Sy upp nya kläder eller hemtextilier.
          <br />
          • Enbart transportera tvättgods.
          <br />
          • Utföra tvätt som underleverantör.
          <br />
        </p>
      </div>
    </div>
  );
};
