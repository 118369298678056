import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import Navigation from "../components/navbar/navbar";
import Footer from "../components/footer/footer";

import { Hem } from "../pages/hem/hem";
import { HemText } from "../pages/hemtext/hemtext";
import { Tjänster } from "../pages/tjanster/tjanster";
import { Företag } from "../pages/foretag/foretag";
import { Rutavdrag } from "../pages/rutavdrag/rutavdrag";
import { Ombud } from "../pages/ombud/ombud";
import { Hem_FlyttStäd } from "../pages/hem-flyttstad/hem-flyttstad";
import { Om, OmText } from "../pages/om/om";
import { Kontakt1, Kontakt2 } from "../pages/kontakt1/kontakt1";
import { Uthyrning } from "../pages/uthyrning/uthyrning";
import { ErrorPage } from "../pages/ErrorPage/ErrorPage";

export const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route exact path="/" element={[<Hem />, <HemText />, <Footer />]} />
          <Route exact path="/tjanster" element={[<Tjänster />, <Footer />]} />
          <Route exact path="/rutavdrag" element={[<Rutavdrag />, <Footer />]} />
          <Route exact path="/foretag" element={[<Företag />, <Footer />]} />
          <Route exact path="/ombud" element={[<Ombud />, <Footer />]} />
          <Route exact path="/hem-flyttstad" element={[<Hem_FlyttStäd />, <Footer />]} />
          <Route exact path="/om" element={[<Om />, <OmText />, <Footer />]} />
          <Route exact path="/kontakt" element={[<Kontakt1 />, <Kontakt2 />, <Footer />]} />
          <Route exact path="/uthyrning" element={[<Uthyrning />, <Footer />]} />
          <Route exact path="*" element={[<ErrorPage />, <Footer />]} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
