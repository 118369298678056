import "./_uthyrning.css";
import phoneIcon from "../../assets/icons/phone.svg";
import mailIcon from "../../assets/icons/mail.svg";

import bild1 from "../../assets/uthyrning/se-3-18.png";
import bild2 from "../../assets/uthyrning/puzzi-8-1.png";

export const Uthyrning = () => {
  return (
    <>
      <div className="uthyrning">
        <div className="header">
          <h1 className="title">Uthyrning</h1>
        </div>
        <div className="uthyr">
          <div className="uthyr-container">
            <div className="columns">
              <div className="picture">
                <img src={bild1} alt="Tvättar för företag" />
              </div>
              <div className="articleContainer left">
                <article className="article">
                  <h2>Kärcher SE 3-18 Compact</h2>
                  <p>
                    Perfekt bärbar tvättmaskin för matt- och textiltvätt. Den är också lätt att ta
                    ut till bilen eller trädgårdsmöblerna.
                    <br />
                    <br />
                    <b>Priser:</b>
                    <br />
                    299kr / dag
                    <br />
                    419kr / helg
                  </p>

                  <h4>
                    <b>Kontakta oss för att hyra</b>
                  </h4>
                  <b>
                    <a href="tel:0709321227">
                      <img src={phoneIcon} width="16" height="16" /> 070-932 12 27
                    </a>
                    <br />
                    <a href="mailto:info@tvattcenter.nu">
                      <img src={mailIcon} width="16" height="16" /> info@tvattcenter.nu
                    </a>
                  </b>
                </article>
              </div>
            </div>
          </div>
        </div>
        <div className="uthyr">
          <div className="uthyr-container">
            <div className="columns revertMobile">
              <div className="articleContainer right">
                <article className="article">
                  <h2>Kärcher Puzzi 8/1 C</h2>
                  <p>
                    Textiltvätten för allt ifrån djuprengöring till lättare fläckborttagning.
                    <br />
                    <br />
                    <b>Priser:</b>
                    <br />
                    299kr / dag
                    <br />
                    419kr / helg
                  </p>

                  <h4>
                    <b>Kontakta oss för att hyra</b>
                  </h4>
                  <b>
                    <a href="tel:0709321227">
                      <img src={phoneIcon} width="16" height="16" /> 070-932 12 27
                    </a>
                    <br />
                    <a href="mailto:info@tvattcenter.nu">
                      <img src={mailIcon} width="16" height="16" /> info@tvattcenter.nu
                    </a>
                  </b>
                </article>
              </div>
              <div className="picture">
                <img src={bild2} alt="Tvättar för företag" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
