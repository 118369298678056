import "./_navbar.css";
import React, { Component } from "react";
import { Navbar, Container, Nav } from "react-bootstrap/";
import { Link } from "react-router-dom";
import logo from "../../assets/logos/whiteLogo.png";

class Navigation extends Component {
  render() {
    return (
      <>
        <Navbar collapseOnSelect className="color-nav" expand="lg" variant="dark">
          <Container>
            <Navbar.Brand>
              {" "}
              <Link to="/">
                <img className="logo" src={logo} classElement="d-inline-block align-top" height="90" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" id="buttonToggleNav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/">
                  Hem
                </Nav.Link>
                <Nav.Link as={Link} to="/tjanster">
                  Våra Tjänster
                </Nav.Link>
                <Nav.Link as={Link} to="/foretag">
                  Företag
                </Nav.Link>
                <Nav.Link as={Link} to="/rutavdrag">
                  Rutavdrag
                </Nav.Link>
                <Nav.Link as={Link} to="/ombud">
                  Ombud
                </Nav.Link>
                <Nav.Link as={Link} to="/hem-flyttstad">
                  Hem & Flyttstäd
                </Nav.Link>
                <Nav.Link as={Link} to="/uthyrning">
                  Uthyrning
                </Nav.Link>
                <Nav.Link as={Link} to="/om">
                  Om
                </Nav.Link>
                <Nav.Link as={Link} to="/kontakt">
                  Kontakt
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default Navigation;
