import "./_hem-flyttstad.css";
import React from "react";
import { Link } from "react-router-dom";

export const Hem_FlyttStäd = () => {
  return (
    <div className="hem-flyttstad">
      <div className="hem-flyttstad-section">
        <p>
          <br />
          <br />
          <h1>Hem och Flyttstäd</h1>
          <br />
          <br />
          <b>Alingsås Tvättcenter erbjuder Hem och Flyttstäd!</b>
          <br />
          <br />
          För mer info kontakta oss på:
          <br />
          <Link className="link" to="/kontakt">
            070-932 12 27
          </Link>
          <br />
          <Link className="link" to="/kontakt">
            info@tvattcenter.nu
          </Link>
          <br />
          <br />
          <br />
          <br />
        </p>
      </div>
    </div>
  );
};
