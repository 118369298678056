import "./_kontakt.css";
import phoneIcon from "../../assets/icons/phone.svg";
import mailIcon from "../../assets/icons/mail.svg";

export const Kontakt1 = () => {
  return (
    <>
      <div className="kontakta">
        <div className="header">
          <h1 className="title">Kontakta Oss</h1>
        </div>

        <div className="alingsas">
          <div className="opentimes">
            <div className="alingsas-article">
              <h2>Alingsås</h2>
              <p>
                Om du vill <b>kontakta oss</b> så gör du det lättast på telefon.
                <br />
                <b>
                  <a href="tel:0709321227">
                    <img src={phoneIcon} width="16" height="16" /> 070-932 12 27
                  </a>
                  <br />
                  <a href="mailto:info@tvattcenter.nu">
                    <img src={mailIcon} width="16" height="16" /> info@tvattcenter.nu
                  </a>
                </b>
              </p>

              <h2>Öppettider</h2>
              <p>
                Våra öppettider kan <b>variera vid helgdagar samt högtider</b>.
              </p>
              <p>
                <b>Lunchstängt: 13-14</b>
              </p>
              <p>
                <b>
                  Måndag: 10:00-17:00
                  <br />
                  Tisdag: 10:00-17:00
                  <br />
                  Onsdag: 10:00-17:00
                  <br />
                  Torsdag: 10:00-17:00
                  <br />
                  Fredag: 10:00-17:00
                  <br />
                  Lördag: Stängt
                </b>{" "}
                (Vid behov ring{" "}
                <b>
                  <a href="tel:0793401585">079-340 15 85</a>
                </b>
                )
              </p>
            </div>
            <iframe
              className="karta2"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1059.2190659805017!2d12.534889391553845!3d57.92958775003106!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4645528729261bd9%3A0x3684ec0e19c1e1dc!2sAlings%C3%A5s%20Tv%C3%A4ttcenter%20AB!5e0!3m2!1ssv!2sse!4v1683809523011!5m2!1ssv!2sse"
              width="600"
              height="450"
              loading="lazy"
            />
          </div>
        </div>

        <div className="alingsas">
          <div className="find-us">
            <div className="alingsas-article">
              <h2>Hitta Oss</h2>
              <p>
                Vi befinner oss på <b>Färgaregatan 14 i Alingsås</b>.
              </p>
              <p>Här kan du kolla runt utanför lokalen med hjälp av Google Street View.</p>
            </div>

            <iframe
              className="street-view"
              src="https://www.google.com/maps/embed?pb=!4v1683809611425!6m8!1m7!1sjXJFahOwB7RoXYn0oIWmdg!2m2!1d57.92961149879783!2d12.53479725497504!3f118.25446302654183!4f-8.146230254806156!5f0.7820865974627469"
              width="600"
              height="450"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const Kontakt2 = () => {
  return (
    <>
      <div className="kontakta2">
        <div className="alingsas">
          <div className="opentimes">
            <div className="alingsas-article">
              <h2>Lerum</h2>
              <p>
                Om du vill <b>kontakta oss</b> så gör du det lättast på telefon.
                <br />
                <b>
                  <a href="tel:0793360515">
                    <img src={phoneIcon} width="16" height="16" /> 079-336 05 15
                  </a>
                  <br />
                  <a href="mailto:info@tvattcenter.nu">
                    <img src={mailIcon} width="16" height="16" /> info@tvattcenter.nu
                  </a>
                </b>
              </p>

              <h2>Öppettider</h2>
              <p>
                Våra öppettider kan <b>variera vid helgdagar samt högtider</b>.
              </p>
              <p>
                <b>
                  Måndag: 09:30-18:00
                  <br />
                  Tisdag: 09:30-18:00
                  <br />
                  Onsdag: 09:30-18:00
                  <br />
                  Torsdag: 09:30-18:00
                  <br />
                  Fredag: 09:30-18:00
                  <br />
                  Lördag: 09:30-14:00
                </b>
              </p>
            </div>
            <iframe
              className="karta2"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2530.720001068055!2d12.260633108438817!3d57.76615668574864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff9a2ff279603%3A0x4216628446a9223c!2sELON%20%2F%20Tv%C3%A4ttcenter!5e0!3m2!1ssv!2sse!4v1683810200498!5m2!1ssv!2sse"
              width="600"
              height="450"
              loading="lazy"
            />
          </div>
        </div>

        <div className="alingsas">
          <div className="find-us">
            <div className="alingsas-article">
              <h2>Hitta Oss</h2>
              <p>
                Vi befinner oss på <b>Göteborgsvägen 2B i Lerum</b>.
              </p>
              <p>Här kan du kolla runt utanför lokalen med hjälp av Google Street View.</p>
            </div>

            <iframe
              className="street-view"
              src="https://www.google.com/maps/embed?pb=!4v1683810256416!6m8!1m7!1sAMza9fzgtXccFigPas5oKw!2m2!1d57.76625622650618!2d12.26306269180978!3f304.4990469363209!4f-9.755936724666014!5f0.7820865974627469"
              width="600"
              height="450"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

// export const Kontakt2 = () => {
//   return (
//     <>
//       {/* <div className="kontakta2">
//         <div className="kontakt">
//           <div className="kontakt-container">
//             <div className="columns">
//               <div className="picture">
//                 <iframe
//                   className="karta"
//                   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2530.720001068055!2d12.260633108438817!3d57.76615668574864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff9a2ff279603%3A0x4216628446a9223c!2sELON%20%2F%20Tv%C3%A4ttcenter!5e0!3m2!1ssv!2sse!4v1683810200498!5m2!1ssv!2sse"
//                   width="600"
//                   height="450"
//                   loading="lazy"
//                 />
//               </div>
//               <div className="articleContainer left">
//                 <article className="article">
//                   <h2>Lerum</h2>
//                   <p>
//                     Om du vill <b>kontakta oss</b> så gör du det lättast på telefon.
//                     <br />
//                     <b>
//                       <a href="tel:0793360515">
//                         <img src={phoneIcon} width="16" height="16" /> 079-336 05 15
//                       </a>
//                       <br />
//                       <a href="mailto:info@tvattcenter.nu">
//                         <img src={mailIcon} width="16" height="16" /> info@tvattcenter.nu
//                       </a>
//                     </b>
//                   </p>

//                   <h2>Öppettider</h2>
//                   <p>
//                     Våra öppettider kan <b>variera vid helgdagar samt högtider</b>.
//                   </p>
//                   <p>
//                     <b>
//                       Måndag: 09:30-18:00
//                       <br />
//                       Tisdag: 09:30-18:00
//                       <br />
//                       Onsdag: 09:30-18:00
//                       <br />
//                       Torsdag: 09:30-18:00
//                       <br />
//                       Fredag: 09:30-18:00
//                       <br />
//                       Lördag: 09:30-14:00
//                     </b>
//                   </p>
//                 </article>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="kontakt">
//           <div className="kontakt-container">
//             <div className="columns revertMobile">
//               <div className="articleContainer right">
//                 <article className="article">
//                   <h2>Hitta Oss</h2>
//                   <p>
//                     Vi befinner oss på <b>Göteborgsvägen 2B i Lerum</b>.
//                   </p>
//                   <p>Här kan du kolla runt utanför lokalen med hjälp av Google Street View.</p>
//                 </article>
//               </div>
//               <div className="picture">
//                 <iframe
//                   className="street-view"
//                   src="https://www.google.com/maps/embed?pb=!4v1683810256416!6m8!1m7!1sAMza9fzgtXccFigPas5oKw!2m2!1d57.76625622650618!2d12.26306269180978!3f304.4990469363209!4f-9.755936724666014!5f0.7820865974627469"
//                   width="600"
//                   height="450"
//                   loading="lazy"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div> */}
//     </>
//   );
// };
