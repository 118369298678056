import "./_hem.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import rullande1 from "../../assets/homepage/rullande-bild-1.png";
import rullande2 from "../../assets/homepage/rullande-bild-2.png";
import rullande3 from "../../assets/homepage/rullande-bild-3.png";
import rullande4 from "../../assets/homepage/rullande-bild-4.png";
import rullande5 from "../../assets/homepage/rullande-bild-5.png";

export const Hem = () => {
  const scrollToHemText = () => {
    document.getElementsByClassName("hemtext")[0].scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className={"homepage"}>
        <div className="homepage__text">
          <h1>
            Välkommen till
            <br />
            Tvättcenter i
            <br />
            Alingsås & Lerum
          </h1>

          <div className="buttonContainer">
            <a className="homeButton" href="/tjanster">
              <span>Våra Tjänster</span>
            </a>
          </div>
        </div>

        <div className="homepage__pictures">
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={5}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 5000 }}
            pagination={{ clickable: true }}>
            <SwiperSlide>
              <img src={rullande1} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={rullande2} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={rullande3} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={rullande4} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={rullande5} />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="homepage__scrollAnimation" onClick={() => scrollToHemText()}>
          <div className="chevron"></div>
          <div className="chevron"></div>
          <div className="chevron"></div>
        </div>
      </div>
    </>
  );
};
