import "./_ErrorPage.css";
import React from "react";

export const ErrorPage = () => {
  return (
    <div className="errorpage">
      <div className="errorpage__container">
        <div className="errorpage__container__text">
          <h1>OJDÅ HALLÅ?!</h1>
          <p>Du ser lite vilsen ut! Gå tillbaka till hemsidan genom att trycka på knappen nedan!</p>
          <a className="errorButton" href="/">
            <span>GÅ TILLBAKA</span>
          </a>
        </div>
      </div>
    </div>
  );
};
