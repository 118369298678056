import "./_om.css";
import React from "react";
import Hallbarhetsredovisning from "../../assets/Hallbarhetsredovisning-2021.pdf";

export const Om = () => {
  return (
    <div className="om">
      <div className="om-section">
        <br />
        <h1 className="title">
          <b>Om Oss</b>
        </h1>
      </div>
      <div className="om-section">
        <p>
          <b>Alingsås Tvättcenter AB</b> finner du i <b>centrum av Alingsås</b>, på{" "}
          <b>Färgaregatan 14</b>, där vi funnits sedan starten i <b>februari 1942</b>. Namnet var då
          Kostymtvätten och startades av <b>Elsa Nordling</b>. Sedan 1986 heter företaget{" "}
          <b>Tvättcenter</b> och arbetar idag med det mesta inom <b>tvätt och slätgörning</b>.
          <br />
          <br />
          Läs vår{" "}
          <a className="link" href={Hallbarhetsredovisning} target="_blank">
            hållbarhetsredovisning för 2021
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export const OmText = () => {
  return (
    <div className="om omtext-bg">
      <div className="omtext-section">
        <h2>
          <b>Kvalitet, Miljö & Arbetsmiljö</b>
        </h2>
        <br />
        <p>
          <b>GRUNDEN FÖR VÅRT FÖRETAG ÄR ATT VI:</b>
        </p>
        <p>
          • Erbjuder tvätt av kläder och andra textilier.
          <br />• Är <b>marknadsledande inom vår bransch</b>.<br />• Har personal med ett{" "}
          <b>gediget intresse</b> för sitt arbete.
          <br />• Har personal som <b>värdesätter den hantverksmässiga noggrannhet</b> som krävs.
          <br />• Arbetar efter ett enhetligt ledningssystem som följer kraven enligt{" "}
          <b>ISO 9001</b>, <b>ISO 14001</b>, <b>ISO 45001</b> samt <b>svensk miljödiplomering</b>.
        </p>

        <br />
        <h4>
          <b>VÅR KVALITETSPOLICY:</b>
        </h4>
        <p>
          Vi skall alltid <b>uppfylla eller överträffa</b> kundens krav, behov och förväntningar så
          att vi därmed <b>bibehåller deras förtroende</b>.
        </p>

        <p>
          <b>DETTA SKA VI UPPNÅ GENOM ATT:</b>
        </p>
        <p>
          • Bygga <b>långsiktiga och varaktiga relationer</b> med våra kunder genom att uppträda
          affärsmässigt med <b>god etik och moral</b>.<br />• Utföra våra uppdrag i <b>rätt tid</b>,
          till <b>rätt pris</b> och till <b>kundens fulla belåtenhet</b>.<br />• Varje medarbetare
          har <b>rätt kompetens</b>, <b>tar ansvar</b> och <b>visar ett engagemang</b> för sin
          uppgift.
          <br />• Utvärdera våra arbetsprocessers effektivitet och därigenom jobba mot{" "}
          <b>ständiga förbättringar</b>.<br />• <b>Uppfylla alla lagkrav och branschregler</b> som
          berör vår verksamhet.
        </p>

        <br />
        <h4>
          <b>VÅR MILJÖPOLICY:</b>
        </h4>
        <p>
          Vi skall uppfattas som ett <b>miljöriktigt företag</b> genom ett <b>aktivt miljöarbete</b>{" "}
          som bidrar till en <b>långsiktig och hållbar utveckling</b> i lokalområdet.
        </p>

        <p>
          <b>DETTA SKA VI UPPNÅ GENOM ATT:</b>
        </p>
        <p>
          • <b>Minimera vår miljöpåverkan</b> och arbeta mot <b>ständiga miljöförbättringar</b>.
          <br />• <b>Följa lagkrav och föreskrifter</b> för miljön som berör vår verksamhet.
          <br />
          • Skapa förutsättningar för miljöriktigt genomförande av våra uppdrag.
          <br />• Samverka med våra leverantörer för mer <b>miljöanpassade produkter</b>.<br />•
          Utbilda vår personal i verksamhetsrelaterad miljökunskap så att de därigenom stimuleras
          till aktiva miljöinsatser.
        </p>

        <br />
        <h4>
          <b>VÅR ARBETSMILJÖPOLICY:</b>
        </h4>
        <p>
          Vi skall vara en <b>attraktiv arbetsgivare</b> genom att skapa hög{" "}
          <b>motivation och trygghet</b> samt verka för att <b>förebygga skada och ohälsa</b> i
          arbetet.
        </p>

        <p>
          <b>DETTA SKA VI UPPNÅ GENOM ATT:</b>
        </p>
        <p>
          • Bedriva ett <b>systematiskt arbetsmiljöarbete</b> enligt tillämpliga krav.
          <br />• <b>Identifiera, bedöma och förebygga arbetsmiljörisker</b> i varje verksamhetsdel.
          <br />• Varje medarbetare som tilldelas en arbetsmiljöuppgift ges{" "}
          <b>tillräckliga kunskaper</b>.<br />
          • Arbetsmiljöfrågorna integreras som en naturlig del i vår verksamhet.
          <br />• Bedriva ett <b>aktivt hälso- och friskvårdsarbete</b> samt rehabiliteringsarbete.
          <br />• Ha en <b>alkohol- och drogfri arbetsmiljö</b> i vår verksamhet.
        </p>

        <br />
        <div className="signature">
          <p>
            <b>Tvättcenter Alingsås AB</b>
          </p>
        </div>
      </div>
    </div>
  );
};
